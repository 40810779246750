<template>
  <v-container class="pa-4">
    <apollo-query
      :query="require('@cpanel/graphql/conference-list.gql')"
      :variables="{ limit: $options.pageSize }"
    >
      <template v-slot="{ result: { loading, error, data }, query }">
        <v-toolbar flat>
          <v-icon>mdi-dots-vertical</v-icon>
          <v-btn icon @click="refreshData(query)"><v-icon>mdi-refresh</v-icon></v-btn>
        </v-toolbar>

        <v-row class="result apollo">
          <v-col>
            <v-data-table
              :headers="headers"
              :items="(data || {}).conferences"
              :items-per-page="50"
              item-key="_id"
              :loading="loading"
              loading-text="Получение данных..."
              :no-data-text="error ? 'ошибка получения данных' : 'нет данных'"
              class="app-conference-table"
              @click:row="onRowClick"
            >
              <template v-slot:item.startDate="{ item }">
                {{ formatStartDate(item.startDate) }}
              </template>
              <template v-slot:item.duration="{ item }">
                <v-chip class="white--text ml-0" :color="item.endDate ? 'green' : 'pink'" label small>
                  <v-icon small class="pr-1">mdi-clock-time-three-outline</v-icon>
                  {{ formatDuration(item) }}
                </v-chip>
              </template>
              <template v-slot:item.place="{ item }">
                {{ (item.place|| {}).name }}
              </template>
              <template v-slot:item.agent="{ item }">
                {{ (item.agent|| {}).name }}
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </template>
    </apollo-query>
  </v-container>
</template>

<script>
export default {
  pageSize: 10,
  data() {
    return {
      headers: [
        {
          value: 'startDate',
          text: 'Началась',
          width: '160px'
        },
        {
          value: 'duration',
          text: 'Длилась',
          width: '120px',
          divider: true
        },
        {
          value: 'agent',
          text: 'Консультант',
          sortable: false
        },
        {
          value: 'place',
          text: 'Локация',
          sortable: false
        }
      ]
    }
  },
  methods: {
    async refreshData(query) {
      await query.refetch()
      this.$toast.success('Данные обновлены успешно')
    },
    formatStartDate(dt) {
      return this.$moment(new Date(dt)).calendar()
    },
    formatDuration(conference) {
      if (!conference.endDate) return 'еще идет'
      const date1 = this.$moment(new Date(conference.startDate))
      const date2 = this.$moment(conference.endDate ? new Date(conference.endDate) : Date.now())
      return this.$moment.duration(date1.diff(date2)).humanize({ ss: 1 })
    },
    onRowClick() {
      //
    }
  }
}
</script>

<style scoped>
  .app-conference-table >>> tbody > tr {
    cursor: pointer;
  }

</style>

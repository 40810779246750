var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-4"},[_c('apollo-query',{attrs:{"query":require('@cpanel/graphql/conference-list.gql'),"variables":{ limit: _vm.$options.pageSize }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_result = ref.result;
var loading = ref_result.loading;
var error = ref_result.error;
var data = ref_result.data;
var query = ref.query;
return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-icon',[_vm._v("mdi-dots-vertical")]),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.refreshData(query)}}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)],1),_c('v-row',{staticClass:"result apollo"},[_c('v-col',[_c('v-data-table',{staticClass:"app-conference-table",attrs:{"headers":_vm.headers,"items":(data || {}).conferences,"items-per-page":50,"item-key":"_id","loading":loading,"loading-text":"Получение данных...","no-data-text":error ? 'ошибка получения данных' : 'нет данных'},on:{"click:row":_vm.onRowClick},scopedSlots:_vm._u([{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatStartDate(item.startDate))+" ")]}},{key:"item.duration",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"white--text ml-0",attrs:{"color":item.endDate ? 'green' : 'pink',"label":"","small":""}},[_c('v-icon',{staticClass:"pr-1",attrs:{"small":""}},[_vm._v("mdi-clock-time-three-outline")]),_vm._v(" "+_vm._s(_vm.formatDuration(item))+" ")],1)]}},{key:"item.place",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.place|| {}).name)+" ")]}},{key:"item.agent",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.agent|| {}).name)+" ")]}}],null,true)})],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }